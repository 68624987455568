/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
//@import "../node_modules/@fullcalendar/daygrid/main.min.css";
//@import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "assets/demo/flags/flags.css";
@import "assets/theme/bandbeat/colors";
@import "assets/theme/bandbeat/flaticon/font/flaticon";
@import "assets/theme/bandbeat/tillnoon-icon/font/tillnoon-icon";


.material-icons-outlined{
  vertical-align: middle;
}
.material-icons-outlined:after {
  content: attr(data-icon);
}

.material-symbols-outlined{
  vertical-align: middle;
}
.material-symbols-outlined:after {
  content: attr(data-icon);
}

.flaticon{
  font-family: Flaticon;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  padding:4px;
  display: inline-block;
}

.till-noon-icon{
  font-family: Tillnoonicon;
  font-style: normal;
  font-size: 24px;
  padding:4px;
  display: inline-block;
}

.till-noon-icon-big {
  font-size: 4rem;
}

.keyword{
  color: $primaryColor;
}

.p-dialog-content {
  overflow: visible !important;
}

.bandbeatPage {
  padding : 2rem 7.5rem;
  margin-right: unset;
}

.layout-content {
  padding: unset !important;
}

.landing-body {
  //background: linear-gradient(147.38deg, #050505 99%, #121212 77%) !important;
  background: $bbBlack !important;
}

.layout-topbar .layout-topbar-left {
  .layout-topbar-main-menu li a {
    color: #FFFFFF;
  }
}

.socialList {
  list-style-type: none;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  li {
    float: left;
    margin: 2px;
    //button {
    //  height : 1.3125rem;
    //  width: 1.3125rem
    //}
  }
}

.mask-icon {
  .p-input-icon-right > i:last-of-type  {
    color: #FFFFFF !important;
  }
}

@media screen and (max-width: 576px) {
  .bandbeatPage {
    margin-right: 0px;
    margin-left: 0px;
    padding:1rem 0;
  }
  .layout-wrapper .layout-content-wrapper {
    margin-top: 0rem !important;
  }

  .layout-wrapper .layout-sidebar {
    top: 7rem !important;
    height: calc(100% - 7rem) !important;
  }

  //.optionBar {
  //  padding: 5rem !important;
  //}
}

@media screen and (min-width: 576px) {
  .coverImage {
    position : relative;
    margin-right: -14rem !important;
    width: calc(50% + 7.5rem) !important;
    height:fit-content !important;
    padding-right:0px !important;
  }
}


@media screen and (max-width: 991px) {
  .layout-wrapper {
    .layout-topbar {
      .layout-topbar-left {
        .menu-button {
          right:unset !important;
          left: 0 !important;
          border-radius: 0px !important;
        }
      }
    }
  }

  .close-icon {
    display: none;
  }
}



.bb-logo-loading {
  font-size: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.l {
  height: 3em;
  width: 1em;
  border-radius: 1em 0 0 0;
  margin-bottom: -2em;
  background-color: $bbGreen;
}
.o {
  width: 4em;
  aspect-ratio: 1;
  border-radius: 100%;
  display: flex;
  background-color: $bbGreen;
}
.waves {
  margin: -3em 0 0 1em;
  width: 2em;
  aspect-ratio: 1;
  border-radius: 100%;
  display: flex;
  background-color: $bbBlack;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.wave {
  width: 15%;
  border-radius: 0.2em;
  margin: 0;
  background-color: white;
}
.wave-short {
  height: 0.50em;
  animation: play-short 1s infinite alternate;
}
.wave-long {
  height: 1.20em;
  animation: play-reverse .5s infinite linear;
}

@keyframes play-short {
  0% {
    height: .7em;
  }
  50% {
    height: 0.5em;
  }
  100% {
    height: .7em;
  }
}
@keyframes play-reverse {
  0% {
    height: 0.6em;
  }
  50% {
    height: 1.2em;
  }
  100% {
    height: 0.6em;
  }
}


// @media screen and (max-width: 576px) {
//   .bb-logo-loading {
//     margin-left: -15%;
//     margin-top: -15%;
//   }
// }
@media screen and (max-width: 1024px) {
  .get-app-banner {
    width: 100vw;
    height: 5rem;
    background-color: #414042;
    display: flex;
    justify-content: center;
    padding: 0.3rem;
    margin: 0;
    padding-top: 1rem;
    
    .p-button:enabled:hover {
      background: transparent;
    }
  
    .p-button:focus {
      box-shadow: none;
      background: transparent;
    }

    .p-button {
      .p-button-icon {
        font-size: 2.2em !important
      }
    }
  }

  .get-app-text {
    font-size: 14px;
    text-align: center;
    padding: 1.4rem;
    margin-top: 0.8rem;
  }

  .get-app-close-btn {
    background: transparent;
    border: none;
    color: $bbGreen;
    margin-top: 0.4rem;
    width: 1rem;
  }

  .get-app-google-img {
    cursor: pointer;
    width: 12rem;
    margin-top: -0.2rem;
  }
}

@media screen and (max-width: 768px) {
  .get-app-banner {
    width: 100vw;
    height: 6rem;
    background-color: #414042;
    display: flex;
    justify-content: center;
    padding: 0.3rem;
    margin: 0;
    padding-top: 1rem;

    .p-button:enabled:hover {
      background: transparent;
    }

    .p-button:focus {
      box-shadow: none;
      background: transparent;
    }

    .p-button {
      .p-button-icon {
        font-size: 2.2em !important
      }
    }
  }

  .get-app-text {
    font-size: 14px;
    text-align: center;
    padding: 1.4rem;
    margin-top: 0.4rem;
    margin-left: 1rem;
  }

  .get-app-close-btn-container{
    text-align: right;
  }

  .get-app-close-btn {
    background: transparent;
    border: none;
    color: $bbGreen;
    margin-top: 0.4rem;
    width: 1rem;
  }

  .get-app-google-img {
    cursor: pointer;
    width: 105%;
    margin-top: 0.3rem;
  }
}

@media screen and (min-width: 1025px) {
  .get-app-banner {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 6rem;
    z-index: 10;
    background-color: #414042;
    display: flex;
    justify-content: center;
  
    .p-button:enabled:hover {
      background: transparent;
    }
    
    .p-button:focus {
      box-shadow: none;
      background: transparent;
    }
    
    .p-button {
      .p-button-icon {
        font-size: 2.4em !important;
      }
    }
  }
    
    .get-app-text {
      font-size: 16px;
      text-align: left;
      margin-top: 2.5rem;
    }
    
    .get-app-close-btn-container {
      text-align: right;
    }
    
    .get-app-close-btn {
      background: transparent;
      border: none;
      color: $bbGreen;
      margin-top: 1.4rem;
    }
    
    .get-app-google-img {
      cursor: pointer;
      width: 40%;
      margin-top: .8rem;
      margin-left: 9rem;
  }
}

.moveScrollUp{
  .p-scrolltop {
    margin-bottom: 6rem;
  }
}
.moveScrollDown{
  .p-scrolltop {
    margin-bottom: 0rem;
  }
}