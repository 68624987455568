@font-face {
  font-family: 'Tillnoonicon';
  src:  url('tillnoon-icon.eot?mtbqsg');
  src:  url('tillnoon-icon.eot?mtbqsg#iefix') format('embedded-opentype'),
    url('tillnoon-icon.ttf?mtbqsg') format('truetype'),
    url('tillnoon-icon.woff?mtbqsg') format('woff'),
    url('tillnoon-icon.svg?mtbqsg#Tillnoonicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="tnicon-"], [class*=" tnicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Tillnoonicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tnicon-Acoustic--Classical-Guitar:before {
  content: "\e900";
}
.tnicon-Acoustic-Bass-Guitar:before {
  content: "\e901";
}
.tnicon-Arrow-Left:before {
  content: "\e902";
}
.tnicon-Arrow-Right:before {
  content: "\e903";
}
.tnicon-Arrow-scroll:before {
  content: "\e904";
}
.tnicon-Audio-Interfaces:before {
  content: "\e905";
}
.tnicon-Bass-Amps:before {
  content: "\e906";
}
.tnicon-Bouzouki:before {
  content: "\e907";
}
.tnicon-Burger:before {
  content: "\e908";
}
.tnicon-Calendar-Month:before {
  content: "\e909";
}
.tnicon-Contact:before {
  content: "\e90a";
}
.tnicon-Currency:before {
  content: "\e90b";
}
.tnicon-Cymbals:before {
  content: "\e90c";
}
.tnicon-DJ-Controllers--Mixers:before {
  content: "\e90d";
}
.tnicon-Double-Bass:before {
  content: "\e90e";
}
.tnicon-Drums--Percussion:before {
  content: "\e90f";
}
.tnicon-Effect-Pedals:before {
  content: "\e910";
}
.tnicon-Electric-Bass-Guitar:before {
  content: "\e911";
}
.tnicon-Electric-Guitar:before {
  content: "\e912";
}
.tnicon-Facebook:before {
  content: "\e913";
}
.tnicon-Guitar-Amps:before {
  content: "\e914";
}
.tnicon-Instagram:before {
  content: "\e915";
}
.tnicon-Keyboards:before {
  content: "\e916";
}
.tnicon-Linkedin:before {
  content: "\e917";
}
.tnicon-Location:before {
  content: "\e918";
}
.tnicon-Map:before {
  content: "\e919";
}
.tnicon-Microphone:before {
  content: "\e91a";
}
.tnicon-Mixboards:before {
  content: "\e91b";
}
.tnicon-Monitors:before {
  content: "\e91c";
}
.tnicon-Other:before {
  content: "\e91d";
}
.tnicon-Pencil:before {
  content: "\e91e";
}
.tnicon-Pianos:before {
  content: "\e91f";
}
.tnicon-Podcast:before {
  content: "\e920";
}
.tnicon-Preamps:before {
  content: "\e921";
}
.tnicon-Recording:before {
  content: "\e922";
}
.tnicon-Rehearsal:before {
  content: "\e923";
}
.tnicon-Saxophone:before {
  content: "\e924";
}
.tnicon-Schedule:before {
  content: "\e925";
}
.tnicon-Software--Plugins:before {
  content: "\e926";
}
.tnicon-Spotify:before {
  content: "\e927";
}
.tnicon-Timelapse:before {
  content: "\e928";
}
.tnicon-Trumpet:before {
  content: "\e929";
}
.tnicon-User:before {
  content: "\e92a";
}
.tnicon-User-Outlined:before {
  content: "\e92b";
}
.tnicon-Users:before {
  content: "\e92c";
}
.tnicon-Wave:before {
  content: "\e92d";
}
.tnicon-Tik-Tok:before {
  content: "\e92e";
}
.tnicon-Youtube:before {
  content: "\e92f";
}
