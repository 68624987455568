$bbGreen: #00B6B2;
$bbBlack: #00000C;
$bbRed: #BA002D;
$bbGrey: #1B1B1E;
$bbWhite: #FFFFFF;
$bbWarning: #FFC200;
$bbSecondaryGrey: #asdasd;
$bbSecondary: #414042;
$bbPurple: #8F57EA;



$primaryColor: $bbGreen;
$buttonBorder:1px solid $bbBlack !default;

$buttonTextColor: $bbWhite !default;
$buttonTextActiveColor:$bbWhite !default;
$buttonTextHoverColor: $bbWhite !default;

$primaryLightColor: #B1EDE8 !default;
$primaryDarkColor: $bbGreen !default;
$primaryDarkerColor: #123F3B !default;
$primaryTextColor: $bbWhite;

$secondaryColor: $bbWhite;
$secondaryLightColor: #C4B5D5 !default;
$secondaryDarkColor: #412B5A !default;
$secondaryDarkerColor: #20152D !default;
$secondaryTextColor: $bbBlack;


$successButtonBg: $bbGreen;
$warningButtonBg: #F8941E;
$helpButtonBg: $bbWhite;
$dangerButtonBg: #B5142C;

      ////button
      //$buttonPadding:.75rem 1.25rem !default;
      //$buttonIconOnlyWidth:3rem !default;
      //$buttonIconOnlyPadding:.75rem 0 !default;
      //$buttonBg:$primaryColor !default;
      //$buttonTextColor:$primaryTextColor !default;
      //$buttonBorder:1px solid $primaryColor !default;
      //$buttonHoverBg:$primaryDarkColor !default;
      //$buttonTextHoverColor:$primaryTextColor !default;
      //$buttonHoverBorderColor:$primaryDarkColor !default;
      //$buttonActiveBg:$primaryDarkerColor !default;
      //$buttonTextActiveColor:$primaryTextColor !default;
      //$buttonActiveBorderColor:$primaryDarkerColor !default;
      //$raisedButtonShadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !default;
      //$roundedButtonBorderRadius:2rem !default;
      //
      //$textButtonHoverBgOpacity:.04 !default;
      //$textButtonActiveBgOpacity:.16 !default;
      //$outlinedButtonBorder:1px solid !default;
      //$plainButtonTextColor:$textSecondaryColor !default;
      //$plainButtonHoverBgColor:$shade200 !default;
      //$plainButtonActiveBgColor:$shade300 !default;


$shade000:#252525;    //surface
$shade100:#1C1B1F;    //header background
$shade200:$bbGreen;    //hover background
$shade300:#dfe7ef;    //border, divider
$shade400:#d3dbe3;    //input border
$shade500:#c6ccd7;    //input icon
$shade600:#708da9;    //text secondary color
$shade700:#FFFFFF;    //text color
$shade800:#E6E1E5;    //unused
$shade900:#212529;    //unused

$additional1: #61b23b;
$additional2: #29303C;
